document.addEventListener('DOMContentLoaded', function () {
    iniciarApp();

});

function iniciarApp() {
    manejarMenu();
    scrollNav();
    agregarClaseSiEsGrande();
    menuDespliegue();
    eventListeners();
    if (window.location.pathname === '/') {
        carruselNoticias();
    };
    if (window.location.pathname === '/usuarios') {
        animateLine();
        tabs();
        mostrarSeccion()
    };


    function eventListeners() {
        const mobileMenu = document.querySelector('.mobile-menu');
        mobileMenu.addEventListener('click', navegacionResponsive);
    }

    const navegacionInicial = document.querySelector('.navegacion-principal');
    if (navegacionInicial) {
        navegacionInicial.classList.remove('mostrar');
    }

    function navegacionResponsive() {
        const navegacion = document.querySelector('.navegacion-principal');
        if (navegacion.classList.contains('mostrar')) {
            navegacion.classList.remove('mostrar');
        } else {
            navegacion.classList.add('mostrar');
        }
    }

    window.addEventListener("resize", function () {
        setTimeout(agregarClaseSiEsGrande, 200);
    });

}




/***************************************************Galeria****************************************** */
if (window.location.pathname.endsWith('/galeriaDominical') ||
    window.location.pathname.endsWith('/galeriaJovenes') ||
    window.location.pathname.endsWith('/galeriaNinos') ||
    window.location.pathname.endsWith('/galeriaTedeum') ||
    window.location.pathname.endsWith('/galeriaComunitaria')) {
    function galeriaImagenes() {
        let slideIndex = 1;
        showSlides(slideIndex);

        document.getElementById('prevG').addEventListener('click', function () {
            plusSlides(-1);
        });

        document.getElementById('nextG').addEventListener('click', function () {
            plusSlides(1);
        });

        const thumbnails = document.querySelectorAll('.demo');
        thumbnails.forEach((thumbnail, index) => {
            thumbnail.addEventListener('click', function () {
                currentSlide(index + 1);
            });
        });

        // Añadir el event listener para los botones "Ver más"
        const verMasBtns = document.querySelectorAll('.ver-mas-btn');
        verMasBtns.forEach((btn, index) => {
            btn.addEventListener('click', function () {
                document.getElementById(`GD${index + 1}`).style.display = 'block';
            });
        });

        // Control adelante y atrás
        function plusSlides(n) {
            showSlides(slideIndex += n);
            hideAllContent();
        }

        // Miniatura de imágenes
        function currentSlide(n) {
            showSlides(slideIndex = n);
            hideAllContent();
        }

        function showSlides(n) {
            let i;
            let slides = document.getElementsByClassName("mySlides");
            let dots = document.getElementsByClassName("demo");
            let captionText = document.getElementById("caption");
            if (n > slides.length) { slideIndex = 1 }
            if (n < 1) { slideIndex = slides.length }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[slideIndex - 1].style.display = "block";
            dots[slideIndex - 1].className += " active";
            captionText.innerHTML = dots[slideIndex - 1].alt;
        }

        function hideAllContent() {
            for (let i = 1; i <= 4; i++) {
                document.getElementById(`GD${i}`).style.display = 'none';
            }
        }
    }

    document.addEventListener('DOMContentLoaded', galeriaImagenes);

    function verMas() {
        const slideIndex = getCurrentSlideIndex();
        document.getElementById(`GD${slideIndex}`).style.display = 'block';
        const agrandar = document.querySelectorAll('.gallery-image');

        // event handler
        agrandar.forEach(function (fotos) {
            fotos.onclick = function () {
                mostrarImagen(fotos.cloneNode(true));
            }
        });
    }
    function mostrarImagen(fotos) {
        const modal = document.createElement('DIV')
        modal.classList.add('modal')
        modal.onclick = cerrarModal
        //Genearar imagen modal
        modal.appendChild(fotos)

        //insercion 
        const body = document.querySelector('body')
        body.classList.add('overflow-hidden')
        body.appendChild(modal)

        //boton cerrar modal
        const cerrarModalBtn = document.createElement('BUTTON')
        cerrarModalBtn.textContent = 'CERRAR'
        cerrarModalBtn.classList.add('btn-cerrar')
        cerrarModalBtn.onclick = cerrarModal
        modal.appendChild(cerrarModalBtn);
    }
    function cerrarModal() {
        const modal = document.querySelector('.modal')
        modal.classList.add('fade-out')
        setTimeout(() => {
            if (modal) {
                modal.remove();
            }
            const body = document.querySelector('body')
            body.classList.remove('overflow-hidden')
        }, 500);

    }

    function getCurrentSlideIndex() {
        let slides = document.getElementsByClassName("mySlides");
        for (let i = 0; i < slides.length; i++) {
            if (slides[i].style.display === "block") {
                return i + 1;
            }
        }
        return 1;
    }
}

/*************************************************** Versión móvil ***********************************************/
function agregarClaseSiEsGrande() {
    var elementoMovil = document.getElementById("v-movil");

    // Verificar el ancho de la pantalla
    if (window.innerWidth > 768) {
        // Agregar la clase si la pantalla es mayor de 768px
        elementoMovil.classList.add("hidden");
    } else {
        // Remover la clase si la pantalla es menor o igual a 768px
        elementoMovil.classList.remove("hidden");
    }
}

//mensaje movil
window.addEventListener("load", agregarClaseSiEsGrande);

//Scroll

function scrollNav() {
    const enlaces = document.querySelectorAll('.navegacion-principal a');

    enlaces.forEach(enlace => {
        enlace.addEventListener('click', function (e) {


            const seccionScroll = e.target.attributes.href.value;
            const seccion = document.querySelector(seccionScroll);
            seccion.scrollIntoView({ behavior: "smooth" });

        });
    });
}

/********************************************************Menu**********************************/
function manejarMenu() {
    // Obtener el menú
    var modal = document.getElementById("miMenu");

    // Obtener el botón para abrir el menú
    var btn = document.getElementById("miGYP");

    // Obtener el span de cierre
    var span = document.getElementsByClassName("cierre")[0];

    // Abrir el menú al hacer clic en el botón
    btn.onclick = function () {
        modal.style.display = "block";
    };

    // Cerrar el menú al hacer clic en el span de cierre
    span.onclick = function () {
        modal.style.display = "none";
    };

    // Cerrar el menú al hacer clic fuera del menú
    window.onclick = function (event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    };
}



/***********************************menu desplegable********************************************/
function menuDespliegue() {
    const toggleBtns = [
        { btn: "toggleBtn", menu: "dropdownMenu" },
        { btn: "toggleBtn2", menu: "dropdownMenu2" },
        { btn: "toggleBtn3", menu: "dropdownMenu3" },
        { btn: "toggleBtn4", menu: "dropdownMenu4" },
    ];

    toggleBtns.forEach(({ btn, menu }) => {
        const toggleBtn = document.getElementById(btn);
        const dropdownMenu = document.getElementById(menu);

        toggleBtn.addEventListener("click", function () {
            dropdownMenu.style.display = (dropdownMenu.style.display === "block") ? "none" : "block";
        });

        window.addEventListener("click", function (event) {
            if (!event.target.matches(`#${btn}`) && !event.target.matches(`#${menu}`)) {
                dropdownMenu.style.display = 'none';
            }
        });
    });
}

/**********************************Carrusel noticias************************ */
if (window.location.pathname === '/') {
    function carruselNoticias() {
        const createHtmlStructure = (sliderSelector, images) => {
            const parent = document.querySelector(sliderSelector);

            images.forEach((slideImg, index) => {
                const { img, title, description, url } = slideImg;
                const slideItem = `
            <div
                class="item"
                style="background-image: url('${img}')"
                data-attribute="${index}"
                data-url="${url}"
            >
                <div class="content">
                    <div class="name">${title}</div>
                    <div class="description">${description}</div>
                    <button><a href="${url}" class="see-more">Ver mas</a></button>
                </div>
            </div>
            `;
                const divFragment = document.createRange().createContextualFragment(slideItem);
                parent.appendChild(divFragment);
            });

            const html = `
        <div class="buttons">
            <button class="prev">Anterior</button>
            <button class="next">Siguiente</button>
        </div>
        `;
            const fragment = document.createRange().createContextualFragment(html);
            parent.parentElement.appendChild(fragment);
        };

        const applyAdditionalStyles = () => {
            const items = document.querySelectorAll('.item');
            const screenSize = window.innerWidth;

            items.forEach((item, index) => {
                if (screenSize > 380) {
                    if (index >= 6) {
                        item.style.display = 'none';
                    }
                } else {
                    item.style.display = 'block'; // Restaura la visibilidad por defecto
                }
            });
        };

        createHtmlStructure('.slider', slideImages);
        applyAdditionalStyles(); // Llama a la función al cargar la página para aplicar los estilos iniciales.

        const $slider = document.querySelector('.slider');
        const $next = document.querySelector('.next');
        const $prev = document.querySelector('.prev');

        window.addEventListener('resize', () => {
            applyAdditionalStyles();
        });

        $next.addEventListener('click', () => {
            const items = document.querySelectorAll('.item');
            $slider.appendChild(items[0]);
            applyAdditionalStyles();
        });

        $prev.addEventListener('click', () => {
            const items = document.querySelectorAll('.item');
            $slider.prepend(items[items.length - 1]);
            applyAdditionalStyles();
        });

    };
};

function videoslider(links) {
    document.querySelector(".slider-videos").src = links;
};

/********Mensajes*************/
function animateLine() {
    var line = document.querySelectorAll('.linea-mensaje');

    var tl = gsap.timeline({
        onComplete: function () {
            tl.restart();
        }
    });

    gsap.defaults({ ease: "circ.inOut" });

    var duration = 3;  // Duración de la animación
    var y = 50;       // Valor de la traslación en el eje Y
    var staggerTime = 9; // Tiempo de intervalo entre las animaciones de cada línea (5 segundos de duración + 2 segundos de lectura)

    tl
        .add(gsap.fromTo(
            line, {
            opacity: 0,
            y: y,
        }, {
            opacity: 1,
            y: 0,
            duration: duration,
            stagger: staggerTime
        }
        ))
        .add(gsap.to(
            line, {
            opacity: 0,
            y: -y,
            delay: duration,
            duration: duration,
            stagger: staggerTime
        }
        ), duration);
}


/******************TABS*************************/
let paso = 1;
function mostrarSeccion() {

    const seccionAnterior = document.querySelector('.mostrarUsuario');
    if(seccionAnterior){
        seccionAnterior.classList.remove('mostrarUsuario');
    }

    const pasoSelector = `#paso-${paso}`;
    const seccion = document.querySelector(pasoSelector);
    seccion.classList.add( 'mostrarUsuario');

    //Quita color a tab
    const tabAnterior = document.querySelector('.actual');
    if(tabAnterior) {
        tabAnterior.classList.remove('actual');
    }

    //Resaltar tab
    const tab = document.querySelector(`[data-paso="${paso}"]`);
    tab.classList.add('actual');

}

function tabs(){
    const botones = document.querySelectorAll('.tabs button');

    botones.forEach(boton => {
        boton.addEventListener('click', function(e){
            paso = parseInt(e.target.dataset.paso);
            mostrarSeccion();
        });
    
    })

}

